<template>
  <div class="home">
    <RegisteredPlayers/>
  </div>
</template>

<script>
// @ is an alias to /src
import RegisteredPlayers from '@/components/RegisteredPlayers.vue'

import AudioService from "@/service/audio.js";

export default {
  name: 'home',
  components: {
    RegisteredPlayers
  },
  mounted() {
    AudioService.sounds().gameIntro();
  }
}
</script>
