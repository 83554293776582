<template>
  <div
    class="ranking-list"
    id="ranking-list"
    style="padding: 50px; background-color: #007e98"
  >
    <div><img src="/img/gamelogo.png" style="max-height: 150px" /></div>

    <table
      cellpadding="0"
      cellspacing="0"
      width="100%"
      style="height: 100%; background-color: white"
    >
      <tbody>
        <tr>
          <td align="left" valign="top">
            <strong>{{ "Ranking" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "PlayerName" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "WrongScans" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "Time" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "Date" | t }}</strong>
          </td>
        </tr>
        <tr v-bind:key="reg.id" v-for="(reg, index) in registrations">
          <td>
            <span>{{ index + 1 }}</span>
          </td>
          <td>{{ reg.player.name }}</td>
          <td>{{ reg.result.errors }}</td>
          <td>{{ reg.result.time | time }}</td>
          <td>{{ reg.playedAt.toDate() | date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Api from "@/service/api.js";
import dayjs from "dayjs";

export default {
  name: "Ranking",
  props: ["date"],
  data: function() {
    return {
      registrations: [],
    };
  },
  mounted() {
    let winningMonth = dayjs(this.date || dayjs())
      .endOf("month")
      .endOf("day");

    Api.fetchFullRanking(winningMonth).then((registrations) => {
      this.registrations = registrations;
    });
  },
};
</script>
