<template>
  <div class="ranking-list" id="ranking-list">
    <table cellpadding="0" cellspacing="0" width="100%" style="height: 100%;">
      <tr>
        <td height="100%">
          <div class="bodytext">
            <div class="container text-left">
              <div class="height25"></div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="headline">
                    {{'RankingList'|t}}
                    <div class="bodytext relative" style="display: block;letter-spacing: 0;">
                     {{'NextDraw' | t}}
                      <small class="disturber">{{ nextDraw }}</small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 text-right">
                  <div class="headline">
                    {{'Next' | t}}
                    <img v-on:click="codeScanned('OK')" src="img/ok-code.png" width="120" height="auto" />
                  </div>
                </div>
              </div>
               <Ranking :registration="registration" :currentPlayerResult="result"></Ranking>
            </div>
            <div class="height50"></div>
          </div>
          <!-- body -->
        </td>
      </tr>
    </table>
   
  </div>
</template>

<script>
import Api from '@/service/api.js';
import Ranking from "@/components/Ranking.vue";

import scannedInput from "@/mixins/scanned-input.js";

export default {
  props: [ "registration", "result" ],
  mixins: [ scannedInput ],
  components: { Ranking },
  data: function() {
    return {
      nextDraw: ''
    };
  },
  timers: {
    resetGame: { time: 10000, autostart: true, repeat: false }
  },
  created() {
    this.$on('scanned-input', this.codeScanned);
  },
  mounted() {
    this.nextDraw = Api.currentWinningMonth().format('DD.MM.YYYY');
  },
  methods: {
    resetGame() {
      this.$router.push("/");
    },
    codeScanned(code) {
      switch (code) {
        case "OK":
          this.resetGame();
          break;
      }
    }
  }
};
</script>
