<template>
  <div>
    
    <div id="game" v-if="!(showTimer || showIntermediaryCorrectHit || showFinalCorrectHit)">

        <div id="game-levels" class="absolute container-levels" v-bind:style="{ backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) ), url('+currentLevel.image+')', backgroundSize: 'cover' }" style="">
            <div id="qr-codes">
                <div v-bind:key="index" v-for="(code, index) in qrcodes" v-bind:style="{outline: code.value == 'OK' ? '5px solid' : '', outlineColor: code.value == 'OK' ? 'rgb(0,126,152)' : 'rgb(166,13,31)', top: code.top+'px', left: code.left+'px', width: size+'px' }" class="qrcode">
                  <img v-on:click="codeScanned(code.value, 'A')" v-on:contextmenu.prevent="codeScanned(code.value, 'B')" v-bind:src="code.code"  />
                </div>
            </div>
        </div>

        <div class="relative container-fluid">
            <div class="row">
                <div class="col-sm-12">

                    <div class="head relative">
                        <div class="gamelogo relative"><img src="img/gamelogo.png"></div>
                        <div class="border-h relative">
                          <div>
                            <div class="level" id="level"><span>{{'Level' | t }}</span> {{this.currentLevelId+1}} / {{ this.levels.length }}</div>
                            <div v-if="mode == 'singleplayer'" class="time" id="time"><span>{{'Time' | t}}</span> {{ time | time }}</div>
                            <div v-if="mode == 'singleplayer'" class="center" id="center">
                                <img class="misterscanmaster" src="img/misterscanmaster.svg">
                                <img class="missscanmaster" src="img/missscanmaster.svg">
                                <span>{{ 'Errors' | t}}</span>
                                {{ score.errors | leftpad }}
                            </div>
                          </div>
                            <div v-if="mode !== 'singleplayer'" class="multiuser-box" id="multiuser" style="display: block;">
                                <img class="misterscanmaster" src="img/misterscanmaster.svg">
                                <img class="missscanmaster" src="img/missscanmaster.svg">
                                <div class="user-1">
                                    <span>{{ 'Points' | t }}</span>
                                    <div class="number">{{multiplayerScore.player1}}</div>
                                </div>
                                <div class="user-2">
                                    <span>{{ 'Points' | t }}</span>
                                    <div class="number">{{multiplayerScore.player2}}</div>
                                </div>
                                <div class="clearfix"></div>
                            </div>

                            <div class="user" style="margin-left: 10px; overflow: visible; height: 82px;" >
                              <span>Exit</span>

                              <img src="img/exit.png" style="height: 100%" v-on:click="codeScanned('EX')">
                            </div>

                            <div v-if="mode == 'singleplayer'" class="user" id="user"><span>{{'User' | t }}</span> {{ registration.player.name }}</div>
                            <div v-else class="user" id="multiuser" style="text-transform: none;"><span>Game</span> Multiplayer</div>

                            <div class="clearfix"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div class="game-overlay" id="game-overlay" v-if="showTimer || showIntermediaryCorrectHit || showFinalCorrectHit">

        <div v-if="showTimer" >
            <img src="img/FAQ-Game-BG.png" class="faq-image" />
            <div class="timer" id="timer">
              <span>5</span>
              <span>4</span>
              <span>3</span>
              <span>2</span>
              <span>1</span>
            </div>
        </div><!-- timer -->

        <div class="msg" id="correct-hit" v-if="showIntermediaryCorrectHit" >
            <div class="msg-body text-center">
                <div class="headline-above">
                  <span>{{ score.time | time }}
                  </span>
                </div>
                <div class="time-rest">{{ time | time }}</div>
                <h1 class="headline">{{ 'CorrectHit' | t}}</h1>
                <p>{{ 'GoOnFirst' | t }}<strong> {{'Level' | t}} {{this.currentLevelId+2}}</strong><br>{{ 'GoOnSecond' | t }}</p>
                <img v-on:click="codeScanned('NEXT')" style="margin-top: 20px;" src="img/next-code.png" width="80" height="auto">
            </div>
        </div>

        <div class="msg" id="winning-screen" v-if="showFinalCorrectHit && mode == 'singleplayer'" >
            <div class="msg-body text-center">
                <div class="headline-above"><span>{{ 'GameOver' | t}}</span></div>
                <div class="time-rest">{{'FinishedFirst' | t }} {{ this.levels.length }} {{'FinishedSecond' | t}}</div>
                <h1 class="headline blink_me game-red">{{ 'Congratulations' | t }}</h1>
                <p>{{ 'GoOnToRankingFirst' | t}} <strong>{{ 'RankingList' | t }}</strong><br>{{ 'GoOnToRankingSecond' | t }}</p>
                <img v-on:click="codeScanned('END')" style="margin-top: 20px;"  src="img/end-code.png" width="80" height="auto">
            </div>
        </div>

        <div class="msg" id="multiuser-winning-screen" v-if="showFinalCorrectHit && mode == 'multiplayer'" >
          <div class="msg-body text-center">
            <div class="headline-above"><span>{{ 'GameOver' | t}}</span></div>
            <div class="time-rest">{{'FinishedFirst' | t }} {{ this.levels.length }} {{'FinishedSecond' | t}}</div>
            <h1 class="headline blink_me game-red">{{ multiplayerScore.player1 > multiplayerScore.player2 ? 'Player1Win' : 'Player2Win' | t}} {{ 'Congratulations' | t }}</h1>
            <p>{{ 'GoOn' | t }}</p>
            <img v-on:click="codeScanned('END')"  style="margin-top: 20px;" src="img/end-code.png" width="80" height="auto">
          </div>
        </div>
       
    </div>

    <!-- Prefetch images -->
    <img v-for="level in levels" v-bind:key="level.image" v-bind:src="level.image" style="display:none;" />
    <img src="img/next-code.png" style="display:none;" />
    <img src="img/end-code.png" style="display:none;" />
  </div>
</template>

<style scoped>
.qrcode {
  position: absolute;
  display: inline-block;
  padding: 30px;
  background-color: white;   
}

.faq-image {
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%; 
}

</style>

<script>

import Api from '@/service/api.js';
import scannedInput from '@/mixins/scanned-input.js';
import CodeGenerator from './code-generator'

import AudioService from "@/service/audio.js";

const size = 115;

const levelConfig = "/img/levels/levels.json";

export default {
  mixins: [scannedInput],
  props: [
    'registration'
  ],
  data: function() {
    return {
      size: size,
      showTimer: true,
      showFinalCorrectHit: false,
      showIntermediaryCorrectHit: false,
      startTime: 0,
      time: 0,
      currentLevelId: 0,
      levels: [],
      score: { errors: 0, time: 0 },
      multiplayerScore: { player1: 0, player2: 0 },
      qrcodes: [
        { top: 250, left: 300, code: 'img/ok-code.svg'},
      ]
    };
  },
  timers: {
    updateTime: { time: 70, autostart: false, repeat: true },
    startGame: { time: 6000, autostart: true, repeat: false},
    modeInterval: { time: 0, autostart: false, repeat: true },
    timesUp: { time: 4*60*1000, autostart: true, repeat: false}
  },
  computed: {
      currentLevel() {
          return this.levels[this.currentLevelId];
      },
      hasMoreLevels() {
        return this.levels.length > this.currentLevelId+1;
      },
      mode() {
       return this.registration ? 'singleplayer' : 'multiplayer';
      }
  },
  created() {
    this.$on('scanned-input', this.codeScanned);

    this.fetchLevels();
  },
  mounted() {
      AudioService.sounds().startScreenLevels();
  },
  methods: {
    fetchLevels() {
      fetch(levelConfig)
      .then((response) => {
        return response.json();
       })
       .then(config => this.levels = config.levels);
    },
    startGame() {
      this.regenerateCodes();

      this.timers.modeInterval.time = this.currentLevel.modeInterval || 1000;
      
      this.startTime = new Date().getTime();
      this.showTimer = false;
      this.$timer.start("updateTime");
      this.$timer.start("modeInterval");
    },
    codeScanned(code, player) { 
      switch(code) {
        case "OK":
          this.observedKeys = [];
          this.$timer.stop("updateTime");

          if (player === 'A') {
            this.multiplayerScore.player1++;
          } else {
            this.multiplayerScore.player2++;
          }

          this.score.time = this.score.time + this.time;
          if (this.hasMoreLevels) {
            this.showIntermediaryCorrectHit = true;
          } else {
            this.showFinalCorrectHit = true;
          }

          AudioService.sounds().gameLevelOver();
          Api.blink(1, 1000);

          break;
        case "WRONG":
          this.score.errors++;
          break;
        case "NEXT":
          this.nextLevel();
          break;
        case "END":
          this.finish();
          break;
        case "EX":
          this.exit();
          break;
      }
    },
    updateTime() {
      this.time = new Date().getTime() - this.startTime;
    },
    modeInterval() {
      switch(this.currentLevel.mode || 'COLOR_SWITCH') {
        case 'COLOR_SWITCH':
          this.switchColors();
          break;
        case 'REGENERATE_CODES':
          this.regenerateCodes();
          break;
      }
    },
    timesUp() {
       this.$router.push("/");
    },
    switchColors() {
      let nextGreen = CodeGenerator.randomInt(this.qrcodes.length);

      this.qrcodes = this.qrcodes.map( (qrcode, index) => index === nextGreen ? { ...qrcode, value: "OK", code: "img/ok-code.svg" } : { ...qrcode, value: "WRONG", code: "img/wrong-code.svg" });
    },
    regenerateCodes() {
      this.qrcodes = CodeGenerator.generateCodes(this.currentLevel.numCodes, size, this.currentLevel.ignoredRects);
    },
    nextLevel() {
      this.currentLevelId++;
      this.showIntermediaryCorrectHit = false;

      this.startGame();
    },
    finish() {
      Api.notifyPlayedGame({ result: this.score });
      
      if (!this.registration) {
        this.$router.push({ name: 'home' });
        return;
      }
      if (!this.registration.isAnonymous) {
        Api.saveScore(this.registration.id, { result: this.score });
      }
      
      this.$router.push({ name: 'end', params: { registration: this.registration, result: this.score }});
    },
    exit() {
        this.$router.push({ name: 'home' });
    }
  }
};
</script>
