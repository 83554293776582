

import firebase from 'firebase/app';
import 'firebase/firestore';

import dayjs from 'dayjs';


let firebaseConfig = {
    apiKey: "AIzaSyAYbcJukan1f-HHD_5ojr3hrtjRvPU_EKo",
    authDomain: "planzer-scan-master.firebaseapp.com",
    databaseURL: "https://planzer-scan-master.firebaseio.com",
    projectId: "planzer-scan-master",
    storageBucket: "planzer-scan-master.appspot.com",
    messagingSenderId: "497835713769",
    appId: "1:497835713769:web:1fe8bf4f4de9273a1d3c78"
};

firebase.initializeApp(firebaseConfig);

let db = firebase.firestore();


let collection = 'games';

const winEvery = 3;

const fiveMinutesAgo = () => dayjs().add(-5, 'minutes').toDate();
const currentWinningMonth = (month) => {

    let now = month || dayjs().endOf('month').endOf('day');
    let m = now.month();

    let nextWinning = now.add(winEvery - m % winEvery - 1, "month");
    return nextWinning;
}

const rankingQuery = (col, month) => {
    return db.collection(col)
          .where('result.time', '>', 0)
          .where('winningMonth', '==', currentWinningMonth(month).format('YYYYMM'))
          .orderBy('result.time')
          .orderBy('result.errors');
}

export default {
    registerPlayer: (player) => {

        const personalData = player.personal;
        delete player.personal;
        
        return db.collection('personal-data').add(personalData).then(value => {
            return db.collection(collection).add({ 
                player: player, 
                registeredAt: firebase.firestore.FieldValue.serverTimestamp(),
                playedAt: null, 
                result: null, 
                winningMonth: null,
                personalData: value.id
            });
        });
    },
    fetchRegistrations: (callback) => {
        return db.collection(collection)
          .where('playedAt', '==', null)
          .where('registeredAt', '>', fiveMinutesAgo())
          .get()
          .then(result => {
            var registrations = [];
            result.forEach(registration => registrations.push( { ...registration.data(), id: registration.id }));
            callback(registrations);
        });
    },
    fetchRanking: (callback) => {
        return rankingQuery(collection)
          .limit(10)
          .get()
          .then(result => {
            var registrations = [];
            result.forEach(registration => registrations.push( { ...registration.data(), id: registration.id }));
            callback(registrations);
        });
    },
    fetchFullRanking: (month) => {
        return rankingQuery(collection, month)
        .get()
        .then(result => {
          var registrations = [];
          result.forEach(registration => registrations.push( { ...registration.data(), id: registration.id }));
          return registrations;
      });
    },
    saveScore(id, score) {
        return db.collection(collection)
                 .doc(id)
                 .update({ ...score, playedAt: firebase.firestore.FieldValue.serverTimestamp(), winningMonth: currentWinningMonth().format('YYYYMM')})
    
    },
    notifyPlayedGame(score) {
        if(collection !== 'games') { /* only store games if played at the machine */
            return db.collection('played-games')
                     .add({ ...score, playedAt: firebase.firestore.FieldValue.serverTimestamp(), winningMonth: currentWinningMonth().format('YYYYMM')})
        }
    },
    currentWinningMonth,
    configureCollection(col) {
        collection = col;
    },
    blink(blinks, duration) {
        return fetch(`http://localhost:5000/blink?blinks=${blinks}&duration=${duration}`, {
                method: 'POST'
            }
        ).catch(e => console.log("Unable to blink:", e));
    }
};