
var data = {
    sounds: {}
}

const play = (audio) => {
    if (!audio) {
        return;
    }
    audio.currentTime = 0;
    audio.play();
}

export default {
    init: (sounds) => data.sounds = sounds,
    sounds: () => ({
        firstPlace: () => play(data.sounds.firstPlace),
        topRanking: () => play(data.sounds.topRanking),
        noTopRanking: () => play(data.sounds.noTopRanking),
        gameIntro: () => play(data.sounds.gameIntro),
        startScreenLevels: () => play(data.sounds.startScreenLevels),
        gameLevelOver: () => play(data.sounds.gameLevelOver),
    })
};
