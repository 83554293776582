export default {
    data: function () {
        return {
            observedKeys: []
        };
    },
    mounted() {
        document.addEventListener("keydown", this.keypressed);
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.keypressed);
    },
    methods: {
        keypressed(event) {
            console.log("Keyboard event: ", event);  // eslint-disable-line
            const input = this.observedKeys.join("");

            if(event.key === 'Shift') { 
                return;
            }
            if (event.key === 'Enter') {

                const player = input[input.length-1];

                this.$emit('scanned-input', input.slice(0, -1), player);
                this.observedKeys = [];
                return;
            }

            this.observedKeys.push(event.key);

            console.log("Accumulated input: ", input);  // eslint-disable-line
        }
    }
};