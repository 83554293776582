<template>
  <div>
    <table cellpadding="0" cellspacing="0" width="100%" style="height: 100%;">
      <tbody v-if="registrations.length !== 0">
        <tr>
          <td align="left" valign="top">
            <strong>{{ "Ranking" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "PlayerName" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "WrongScans" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "Time" | t }}</strong>
          </td>
          <td align="left" valign="top">
            <strong>{{ "Date" | t }}</strong>
          </td>
        </tr>
        <!-- show following when last player is not under the top 10 ranking -->
        <tr
          class="current"
          v-if="(currentPlayerRanking && currentPlayerRanking > 10) || (registration && registration.isAnonymous)"
        >
          <td align="left" valign="top">
            <span>{{ currentPlayerRanking }}</span>
          </td>
          <td align="left" valign="top">{{ registration.player.name }}</td>
          <td align="left" valign="top">{{ currentPlayerResult.errors }}</td>
          <td align="left" valign="top">
            {{ currentPlayerResult.time | time }}
          </td>
          <td align="left" valign="top">
            {{
              registration.playedAt
                ? registration.playedAt.toDate()
                : new Date() | date
            }}
          </td>
        </tr>
        <tr>
          <td align="left" valign="top">
            <hr />
          </td>
          <td align="left" valign="top">
            <hr />
          </td>
          <td align="left" valign="top">
            <hr />
          </td>
          <td align="left" valign="top">
            <hr />
          </td>
          <td align="left" valign="top">
            <hr />
          </td>
        </tr>
        <tr v-bind:key="reg.id" v-for="(reg, index) in registrations">
          <td>
            <span>{{ index + 1 }}</span>
          </td>
          <td>{{ reg.player.name }}</td>
          <td>{{ reg.result.errors }}</td>
          <td>{{ reg.result.time | time }}</td>
          <td>{{ reg.playedAt.toDate() | date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Api from "@/service/api.js";

import AudioService from "@/service/audio.js";

export default {
  name: "Ranking",
  props: ["registration", "currentPlayerResult"],
  data: function() {
    return {
      registrations: [],
      currentPlayerRanking: null
    };
  },
  mounted() {
    if (!this.currentPlayerResult) {
      return;
    }

    Api.fetchFullRanking().then(registrations => {
      for (let i = 0; i < registrations.length; i++) {
        if (
          this.currentPlayerResult.time <= registrations[i].result.time &&
          this.currentPlayerResult.errors <= registrations[i].result.errors
        ) {
          this.currentPlayerRanking = i + 1;
          AudioService.sounds().topRanking();
          return;
        }
      }

      this.currentPlayerRanking = registrations.length + 1;
      if (this.currentPlayerRanking == 1) {
        AudioService.sounds().firstPlace();
        Api.blink(4, 2000);
      } else if (this.currentPlayerRanking > 10) {
        AudioService.sounds().noTopRanking();
        Api.blink(2, 1200);
      } else {
        AudioService.sounds().topRanking();
        Api.blink(4, 1000);
      }
    });
  },
  timers: {
    loadRanking: { time: 2000, autostart: true, repeat: true, immediate: true }
  },
  methods: {
    loadRanking() {
      Api.fetchRanking(registrations => {
        this.registrations = registrations;
      });
    }
  }
};
</script>
