<template>
  <div id="app">
    <router-view/>

    <audio preload="auto" ref="gameOverFirstPlace" >
      <source src="sounds/Game-Over-ErsterPlatz.wav" type="audio/wav">
    </audio>
    <audio preload="auto" ref="topRanking">
      <source src="sounds/Game-Over-ImTopRanking.wav" type="audio/wav" />
    </audio>
    <audio preload="auto" ref="noTopRanking">
      <source src="sounds/Game-Over-NichtImTopRanking.wav" type="audio/wav" />
    </audio>
    <audio preload="auto" ref="gameIntro">
      <source src="sounds/Game-Intro.wav" type="audio/wav" />
    </audio>
    <audio preload="auto" ref="startScreenLevels">
      <source src="sounds/Game-Start-Screen-Levels.wav" type="audio/wav">
    </audio>
    <audio preload="auto" ref="gameLevelOver" >
      <source src="sounds/Game-Level-Over.wav" type="audio/wav">
    </audio>
  </div>
</template>

<style>

</style>

<script>

import AudioService from "@/service/audio.js";

export default {
  mounted() {
    AudioService.init({
      firstPlace: this.$refs.gameOverFirstPlace,
      topRanking: this.$refs.topRanking,
      noTopRanking: this.$refs.noTopRanking,
      gameIntro: this.$refs.gameIntro,
      startScreenLevels: this.$refs.startScreenLevels,
      gameLevelOver: this.$refs.gameLevelOver,
    })
  }
}
</script>