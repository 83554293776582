import translations from "@/service/i18n/translations.js";

let lang = "en";

const setLang = newLang => {
  lang = newLang.toLowerCase();
};

const getLang = () => lang;

const translate = key => {
  // eslint-disable-next-line no-prototype-builtins
  if (!translations.hasOwnProperty(lang)) {
    return "No Language " + lang;
  }
  // eslint-disable-next-line no-prototype-builtins
  if (!translations[lang].hasOwnProperty(key)) {
    return "No Translation for " + key;
  }

  return translations[lang][key];
};

export default {
  translate,
  setLang,
  getLang,
  supportedLanguages: Object.keys(translations)
};