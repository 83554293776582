import "bootstrap/dist/css/bootstrap.min.css"

import Vue from 'vue'
import VueTimers from 'vue-timers'
import App from './App.vue'
import router from './router'
import Api from '@/service/api.js';
import i18n from '@/service/i18n.js';
 
import dayjs from 'dayjs';

let urlParams = new URLSearchParams(window.location.search);
let gameId = urlParams.get('game') || 'games';
Api.configureCollection(gameId);

Vue.use(VueTimers)

Vue.config.productionTip = false

const leftpad = value => {
  if(value >= 10) {
    return value;
  }
  return '0'+value;
}

function round_to_precision(x, precision) {
  var y = +x + (precision === undefined ? 0.5 : precision/2);
  return y - (y % (precision === undefined ? 1 : +precision));
}

Vue.filter('t', value => i18n.translate(value));
Vue.filter('date', value => dayjs(value).format('DD.MM.YYYY'));
Vue.filter('leftpad', leftpad);
Vue.filter('time', value => {
  const minutes = Math.floor(value / (60*1000));
  const seconds = Math.floor((value - minutes * 60*1000) / 1000);
  const millis = value - minutes * 60*1000 - seconds*1000;

  return leftpad(minutes) + ':' + leftpad(seconds) + ':' + leftpad(round_to_precision(millis, 10) / 10);
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')