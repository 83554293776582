<template>
  <div class="pregame-overlay" id="pregame-overlay" style="overflow: hidden">
    <div
      id="select-register-or-not"
      class="row select-box startscreen"
      style="position: static"
    >
      <div class="gamelogo"><img src="img/gamelogo.png" /></div>

      <div class="col-sm-6  game-blue-bg" @click="codeScanned('NEXT')">
        <table
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="height: 60vh;"
        >
          <tr>
            <td align="center" valign="middle">
              <div class="bodytext">
                <strong class="white">{{ "Multiplayer" | t }}</strong>
              </div>
              <div class="height25"></div>
              <div class="key-visual rankinglist">
                <img src="img/justplay.png" style="height: 291px" />
              </div>
              <div class="height50"></div>
              <div class="height25"></div>
              <div class="info padding-15 white-bg box-shadow" style="display: inline-flex; align-items: center;" >
                <img
                  src="img/next-code.png"
                  style="height: 100px; margin-right: 15px; padding: 15px; "
                />
                <span style="color: #007e98">
                  {{ "PlayNow" | t }}
                  <br />
                  <span style="font-size: 30px; color: #007e98">{{ "ScanQr" | t }}</span>
                </span>
              </div>

              <p style="margin-top: 25px;" class="white">
                {{ "descriptionFirst" | t }}
                <br />
                {{ "descriptionSecond" | t }}
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-sm-6  white-bg" @click="codeScanned('OK')">
        <table
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="height: 60vh;"
        >
          <tr>
            <td align="center" valign="middle">
              <div class="bodytext">
                <strong>{{ "Singleplayer" | t }}</strong>
              </div>

              <div class="height25"></div>
              <div class="key-visual rankinglist">
                <img
                  src="img/ranking_list_large.png"
                  style="max-width: 500px; margin-bottom: 0;"
                />
              </div>
              <div class="height25"></div>

              <div class="info padding-15 white-bg box-shadow" style="display: inline-flex; align-items: center;">
                <img
                  src="img/ok-code.png"
                  style="height: 100px; margin-right: 15px; padding: 15px; background-color: white "
                />
                <div style="color: white">
                  {{ "PlayNow" | t }}
                  <br />
                  <span style="font-size: 30px; color: white">{{ "ScanQr" | t }}</span>
                </div>
              </div>

              <p style="margin-top: 25px; padding: 0 25px">
                {{ "RegisterFirst" | t }}<br />{{ "RegisterSecond" | t }}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div
      class="row game-blue-bg"
      style="background: url('img/FAQ-Home-BG.png') center center; height: 40vh; border-top: 5px; border-bottom: 0; border-style: solid; border-color: white"
    >
      <div class="col-sm-12">
        <h1 class="headline white text-center" style="padding-top: 20px">
          {{ "ChoosePlayer" | t }}
        </h1>
      </div>
      <div class="col-sm-12 text-center">
        <div
          class="player-box"
          v-on:click="codeScanned(regWithQR.registration.id)"
          v-for="regWithQR in registrations"
          v-bind:key="regWithQR.registration.id"
          style="margin-left: 1px"
        >
          <!-- <img class="game-logo" src="img/gamelogo.png"> -->
          <div
            class="box-head"
            style="font-family: Open Sans Condensed, sans-serif; font-size: 25px"
          >
            {{ regWithQR.registration.player.name }}
          </div>
          <div class="box-avatar">
            <img
              :src="'img/' + regWithQR.registration.player.avatar + '.svg'"
              width="100"
              height="auto"
            />
          </div>
          <div class="code">
            Scan to start now<br /><img
              v-bind:src="regWithQR.qrcode"
              width="150"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/service/i18n.js";

import QRCode from "qrcode";
import Api from "@/service/api.js";
import scannedInput from "@/mixins/scanned-input.js";

export default {
  mixins: [scannedInput],
  name: "RegisteredPlayers",
  data: function() {
    return {
      currentLanguage: 0,
      registrations: [],
    };
  },
  timers: {
    loadRegistrations: {
      time: 2500,
      autostart: true,
      repeat: true,
      immediate: true,
    },
    languageUpdate: { time: 10000, autostart: true, repeat: true },
  },
  created() {
    this.$on("scanned-input", this.codeScanned);
  },
  mounted() {
    i18n.setLang("en");
  },
  methods: {
    loadRegistrations() {
      Api.fetchRegistrations((registrations) => {
        Promise.all(
          registrations.map((reg) => {
            return QRCode.toDataURL(reg.id).then((dataUrl) => {
              return { registration: reg, qrcode: dataUrl };
            });
          })
        ).then((regWithQR) => (this.registrations = regWithQR));
      });
    },
    codeScanned(code) {
      if (code === "OK") {
        this.startAnanonymousGame();
        return;
      }
      if (code === "NEXT") {
        this.startMultiplayer();
        return;
      }

      const registration = this.registrations.find(
        (reg) => reg.registration.id == code
      );
      if (!registration) {
        console.log("Unknown code scanned"); // eslint-disable-line no-console
        return;
      }
      i18n.setLang(registration.registration.player.language || "en");
      this.$router.push({
        name: "play",
        params: { registration: registration.registration },
      });
    },
    startGame(reg) {
      this.$router.push({ name: "play", params: { registration: reg } });
    },
    startAnanonymousGame() {
      i18n.setLang("en");

      this.$router.push({
        name: "play",
        params: {
          registration: { player: { name: "Anonymous" }, isAnonymous: true },
        },
      });
    },
    startMultiplayer() {
      this.$router.push({ name: "play", params: { registration: null } });
    },
    languageUpdate() {
      this.currentLanguage =
        (this.currentLanguage + 1) % i18n.supportedLanguages.length;
      i18n.setLang(i18n.supportedLanguages[this.currentLanguage]);
      this.$forceUpdate();
    },
  },
};
</script>
