import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Play from '@/views/Play.vue'
import End from '@/views/End.vue'
import RankingPage from "@/views/RankingPage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/play',
    name: 'play',
    component: Play,
    props: true
  },
  {
    path: '/end',
    name: 'end',
    component: End,
    props: true
  },
  {
    path: '/ranking/:date',
    name: 'ranking',
    component: RankingPage,
    props: true
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: RankingPage,
    props: true
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
