const spaceBetweenRects = 30;
const headerHeight = 170;
const maxTop = (size) => window.innerHeight - headerHeight - size;
const maxLeft = (size) => window.innerWidth - size;
const maxIterations = 500;

const intersectRect = (a, b) => {
  return (
    a.left <= b.right &&
    b.left <= a.right &&
    a.top <= b.bottom &&
    b.top <= a.bottom
  );
};

const toRect = (top, left, size) => {
  return {
    left: left,
    top: top,
    right: left + size + spaceBetweenRects,
    bottom: top + size + spaceBetweenRects
  };
};

let randomInt = max => Math.floor(Math.random() * max);

const generateCodes = (numCodes, size, ignoredRects) => {
  let hasOverlap = (codes, top, left) => {
    const newRect = toRect(top, left, size);
    const intersectIgnoredRect = ignoredRects.find(rect => intersectRect(newRect, rect));
    return intersectIgnoredRect || codes.find(code =>intersectRect(newRect, toRect(code.top, code.left, size)));
  };

  const qrcodes = [];
  qrcodes.push({
    value: 'OK',
    top: randomInt(maxTop(size)) + headerHeight,
    left: randomInt(maxLeft(size)),
    code: "img/ok-code.svg"
  });
  let iter = 0;
  while (qrcodes.length !== numCodes && iter < maxIterations) {
    iter++;
    let top = randomInt(maxTop(size)) + headerHeight;
    let left = randomInt(maxLeft(size));
    
    if (hasOverlap(qrcodes, top, left)) {
      continue;
    }

    qrcodes.push({ value: 'WRONG', top: top, left: left, code: "img/wrong-code.svg" });
  }

  return qrcodes;
};

export default {
  generateCodes,
  randomInt
};
