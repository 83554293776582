export default {
  en: {
    Key: "EN",
    PlayNow: "PLAY NOW!",
    PlayWin: "PLAY & WIN!",
    RegisterFirst:
      "Scan all blue QR-Codes as fast as you can",
    RegisterSecond:
      "To take part in the competition, first register on the tablet.",
    descriptionFirst: "Compete against eachother.",
    descriptionSecond: "Whoever scans more blue QR codes wins!",
    User: "Player",
    Level: "Level",
    Time: "Time",
    Errors: "Errors",
    GoOnFirst: "Go on with",
    GoOnSecond: "by scanning this QR code",
    GameOver: "Game over",
    FinishedFirst: "You finished all",
    FinishedSecond: "levels",
    Congratulations: "Congratulations!",
    CorrectHit: "Correct hit!",
    GoOnToRankingFirst: "Go on with the",
    GoOnToRankingSecond: "by scanning this QR code",
    RankingList: "Ranking List",
    Ranking: "Ranking",
    PlayerName: "Player's name",
    WrongScans: "Wrong Scans",
    Date: "Date",
    NextDraw: "Next draw at,",
    Next: "Next",
    ChooseAvatar: "Choose your avatar",
    MissScanMaster: "Miss Scan Master",
    MisterScanMaster: "Mister Scan Master",
    ChooseName: "Choose your player's name",
    PlayerNamePlaceholder: "Enter your player name",
    Prename: "Prename",
    PrenamePlaceholder: "Enter your prename",
    Name: "Name",
    NamePlaceholder: "Enter your name",
    Address: "'Address'",
    AddressPlaceholder: "'Enter address'",
    Zip: "Zip code",
    ZipPlaceholder: "Enter zip code",
    City: "'City'",
    CityPlaceholder: "'Enter city'",
    Phone: "Phone number",
    Optional: "(optional)",
    PhonePlaceholder: "Enter phone number",
    Email: "E-mail address",
    EmailPlaceholder: "Enter e-mail adress",
    Abort: "« abort",
    Register: "Register & Play",
    Singleplayer: "SINGLEPLAYER",
    Multiplayer: "MULTIPLAYER",
    Points: "Points",
    GoOn: "Go back to main menu",
    Player1Win: "Player 1 wins!",
    Player2Win: "Player 2 wins!",
    ChoosePlayer: "Choose Player",
    ScanQr: "Scan QR Code"
  },
  ch: {
    Key: "CH",
    PlayNow: "JETZ SPIELE!",
    PlayWin: "SPIELE & GWÜNNE",
    RegisterFirst:
      "Scan so schnäll wie möglich alli blaue QR-Codes",
    RegisterSecond:
      "Zum am Gwünnspiel teilznäh, regischtrier dich zersch ufem Tablet.",
    descriptionFirst: "Trätet s'zweite gegenänd ah!",
    descriptionSecond: "Wer meh blaui QR-Codes scannt, gwünnt!",
    User: "Spieler",
    Level: "Level",
    Time: "Ziit",
    Errors: "Fähler",
    GoOnFirst: "Mach wiiter mit",
    GoOnSecond: "indem du dä QR-Code scannsch",
    GameOver: "Game Over",
    FinishedFirst: "Du häsch alli",
    FinishedSecond: "Levels beändet",
    Congratulations: "Herzlichä Glückwunsch!",
    CorrectHit: "Träffer",
    GoOnToRankingFirst: "Mach wiiter mit",
    GoOnToRankingSecond: "indem du dä QR-Code scannsch",
    RankingList: "Ranglischte",
    Ranking: "Rang",
    PlayerName: "Spielername",
    WrongScans: "Falschi Scans",
    Date: "Datum",
    NextDraw: "Nächsti Verlosig am",
    Next: "Wiiter",
    ChooseAvatar: "Wähl din Avatar",
    MissScanMaster: "Miss Scan Master",
    MisterScanMaster: "Mister Scan Master",
    ChooseName: "Wähl din Spielername",
    PlayerNamePlaceholder: "Gib din Spielername ih",
    Prename: "Vorname",
    PrenamePlaceholder: "Vorname",
    Name: "Nachname",
    NamePlaceholder: "Nachname",
    Address: "Adresse",
    AddressPlaceholder: "Adresse",
    Zip: "PLZ",
    ZipPlaceholder: "PLZ",
    City: "Ort",
    CityPlaceholder: "Ort",
    Phone: "Telefonnummer",
    Optional: "(optional)",
    PhonePlaceholder: "Telefonnummer",
    Email: "E-Mail",
    EmailPlaceholder: "E-Mail",
    Abort: "« Abbräche",
    Register: "Registrierä & Spiele",
    Singleplayer: "SINGLEPLAYER",
    Multiplayer: "MULTIPLAYER",
    Points: "Pünkt",
    GoOn: "Zrugg zum Hauptmenü",
    Player1Win: "Spieler 1 hät gwunne!",
    Player2Win: "Spieler 2 hät gwunne!",
    ChoosePlayer: "Spieler wähle",
    ScanQr: "QR-Code scännä"
  },
  de: {
    Key: "DE",
    PlayNow: "JETZT SPIELEN!",
    PlayWin: "SPIELEN & GEWINNEN!",
    RegisterFirst:
      "Scanne so schnell wie möglich alle blauen QR-Codes",
    RegisterSecond:
      "Um am Gewinnspiel teilzunehmen, registriere dich zuerst auf dem Tablet.",
    descriptionFirst: "Tretet zu zweit gegeneinander an!",
    descriptionSecond: "Wer mehr blaue QR-Codes scannt, gewinnt!",
    User: "Spieler",
    Level: "Level",
    Time: "Zeit",
    Errors: "Fehler",
    GoOnFirst: "Mache weiter mit",
    GoOnSecond: "indem du diesen QR Code scannst",
    GameOver: "Game Over",
    FinishedFirst: "Du hast alle",
    FinishedSecond: "Levels beendet",
    Congratulations: "Herzlichen Glückwunsch!",
    CorrectHit: "Treffer!",
    GoOnToRankingFirst: "Mache weiter mit",
    GoOnToRankingSecond: "indem du diesen QR Code scannst",
    RankingList: "Rangliste",
    Ranking: "Rang",
    PlayerName: "Spielername",
    WrongScans: "Falsche Scans",
    Date: "Datum",
    NextDraw: "Nächste Verlosung am",
    Next: "Weiter",
    ChooseAvatar: "Wähl deinen Avatar",
    MissScanMaster: "Miss Scan Master",
    MisterScanMaster: "Mister Scan Master",
    ChooseName: "Wähle deinen Spielernamen",
    PlayerNamePlaceholder: "Gib deinen Spielernamen ein",
    Prename: "Vorname",
    PrenamePlaceholder: "Vorname",
    Name: "Nachname",
    NamePlaceholder: "Nachname",
    Address: "Adresse",
    AddressPlaceholder: "Adresse",
    Zip: "PLZ",
    ZipPlaceholder: "PLZ",
    City: "Ort",
    CityPlaceholder: "Ort",
    Phone: "Telefonnummer",
    Optional: "(optional)",
    PhonePlaceholder: "Telefonnummer",
    Email: "E-Mail",
    EmailPlaceholder: "E-Mail",
    Abort: "« Abbrechen",
    Register: "Registrieren & Spielen",
    Singleplayer: "SINGLEPLAYER",
    Multiplayer: "MULTIPLAYER",
    Points: "Punkte",
    GoOn: "Zurück zum Hauptmenü",
    Player1Win: "Spieler 1 hat gewonnen!",
    Player2Win: "Spieler 2 hat gewonnen!",
    ChoosePlayer: "Spieler wählen",
    ScanQr: "QR-Code scannen"
  },
  fr: {
    Key: "FR",
    PlayNow: "JOUE DÈS MAINTENANT!",
    PlayWin: "JOUE ET GAGNE!",
    RegisterFirst:
      "Scanne aussi rapidement que possible tous les codes QR bleus",
    RegisterSecond:
      "Pour participer au concours, inscrivez-vous d'abord sur la tablette.",
    descriptionFirst: "Jouez à deux, l’un ou l’une contre l’autre!",
    descriptionSecond:
      "Celui ou celle qui scanne le plus de codes QR bleus gagne la partie!",
    User: "Joueur",
    Level: "Niveau",
    Time: "Temps",
    Errors: "Erreurs",
    GoOnFirst: "Continue à participer",
    GoOnSecond: "en scannant ce code QR",
    GameOver: "Game Over",
    FinishedFirst: "Tu as terminé tous",
    FinishedSecond: "les niveaux",
    Congratulations: "Félicitations!",
    CorrectHit: "En plein dans le mille!",
    GoOnToRankingFirst: "Continue à participer",
    GoOnToRankingSecond: "en scannant ce code QR",
    RankingList: "Classement",
    Ranking: "Place au classement",
    PlayerName: "Nom de joueur",
    WrongScans: "Mauvais scans",
    Date: "Date",
    NextDraw: "Prochain tirage au sort le",
    Next: "Suivant",
    ChooseAvatar: "Choisis ton avatar",
    MissScanMaster: "Miss Scan Master",
    MisterScanMaster: "Mister Scan Master",
    ChooseName: "Choisis ton nom de joueur",
    PlayerNamePlaceholder: "Saisis ton nom de joueur",
    Prename: "Prénom",
    PrenamePlaceholder: "Prénom",
    Name: "Nom de famille",
    NamePlaceholder: "Nom de famille",
    Address: "Adresse",
    AddressPlaceholder: "Adresse",
    Zip: "NPA",
    ZipPlaceholder: "NPA",
    City: "Localité",
    CityPlaceholder: "Localité",
    Phone: "Numéro de téléphone",
    Optional: "(facultatif)",
    PhonePlaceholder: "Numéro de téléphone",
    Email: "E-mail",
    EmailPlaceholder: "E-mail",
    Abort: "« Annuler",
    Register: "S’inscrire et jouer",
    Singleplayer: "SOLO",
    Multiplayer: "MULTIJOUEURS",
    Points: "Points",
    GoOn: "Retour au menu principal",
    Player1Win: "Joueur 1 a gagné!",
    Player2Win: "Joueur 2 a gagné!",
    ChoosePlayer: "Choisir le joueur",
    ScanQr: "Scanner le code QR"
  },
  it: {
    Key: "IT",
    PlayNow: "GIOCA ORA!",
    PlayWin: "GIOCA E VINCI!",
    RegisterFirst:
      "Scannerizza più in fretta che puoi tutti i codici QR blu",
    RegisterSecond:
      "Per partecipare al concorso è necessario prima registrarsi sul tablet.",
    descriptionFirst: "Gareggia contro un altro giocatore!",
    descriptionSecond: "Vince chi scannerizza più codici QR blu!",
    User: "Giocatore",
    Level: "Livello",
    Time: "Tempo",
    Errors: "Errori",
    GoOnFirst: "Continua",
    GoOnSecond: "scannerizzando questo codice QR",
    GameOver: "Game over",
    FinishedFirst: "Hai completato",
    FinishedSecond: "tutti i livelli",
    Congratulations: "Congratulazioni!",
    CorrectHit: "Trovato!",
    GoOnToRankingFirst: "Continua",
    GoOnToRankingSecond: "scannerizzando questo codice QR",
    RankingList: "Classifica",
    Ranking: "Posizione",
    PlayerName: "Nome giocatore",
    WrongScans: "Scan errati",
    Date: "Data",
    NextDraw: "Prossimo sorteggio:",
    Next: "Avanti",
    ChooseAvatar: "Scegli il tuo avatar",
    MissScanMaster: "Miss Scan Master",
    MisterScanMaster: "Mister Scan Master",
    ChooseName: "Scegli il tuo nome giocatore",
    PlayerNamePlaceholder: "Inserisci il tuo nome giocatore",
    Prename: "Nome",
    PrenamePlaceholder: "Nome",
    Name: "Cognome",
    NamePlaceholder: "Cognome",
    Address: "Indirizzo",
    AddressPlaceholder: "Indirizzo",
    Zip: "NPA",
    ZipPlaceholder: "NPA",
    City: "Località",
    CityPlaceholder: "Località",
    Phone: "Numero di telefono",
    Optional: "(opzionale)",
    PhonePlaceholder: "Numero di telefono",
    Email: "E-mail",
    EmailPlaceholder: "E-mail",
    Abort: "« Annulla",
    Register: "Registrati e gioca",
    Singleplayer: "GIOCATORE SINGOLO",
    Multiplayer: "PIÙ GIOCATORI",
    Points: "Punti",
    GoOn: "Torna al menù principale",
    Player1Win: "Il giocatore 1 ha vinto!",
    Player2Win: "Il giocatore 2 ha vinto!",
    ChoosePlayer: "Scegli il giocatore",
    ScanQr: "Scansione codice QR"
  },
};
